import { makeStyles } from "@mui/styles"
import { styled } from "@mui/material/styles"

export default makeStyles(theme => ({
  test: {
    marginBottom: "3%",
  },
  codeEditor: {
    margin: 27,
  },
  checkButton: {
    cursor: "pointer",
    appearance: "none",
    borderRadius: "4px",
    background: "#1665c1",
    float: "left",
    color: "#ffffff",
    width: "200px",
    height: "44px",
    fontSize: "16px",
    border: "1px solid #1665c1",
    textTransform: "uppercase",
    fontWeight: 400,
    fontFamily: "Roboto",
    "&:hover": {
      background: "#115eb6",
      borderColor: "#115eb6",
      color: "#ffffff",
    },
    "&:disabled": {
      background: "#ebebeb",
      borderColor: "#98999d",
      color: "#98999d",
    },
  },
  checkButtonWt: {
    cursor: "pointer",
    appearance: "none",
    borderRadius: "4px",
    background: "#ffffff",
    float: "left",
    color: "#1565C0",
    width: "200px",
    height: "44px",
    fontSize: "16px",
    border: "1px solid #1564c0",
    textTransform: "uppercase",
    fontWeight: 400,
    fontFamily: "Roboto",
  },
  textFileName: {
    fontSize: 18,
    fontWeight: 400,
    fontFamily: "Roboto",
    lineHeight: 1,
  },
  textMess: {
    fontWeight: 400,
    fontSize: 14,
    fontFamily: "Roboto",
    lineHeight: 1,
    padding: "15px 5px",
    boxSizing: "content-box",
    height: 14,
  },

  /* new test styles */
  testQuest: {
    "& p": {
      fontSize: 18,
      lineHeight: "155%",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 400,
      marginTop: 0,
      marginBottom: 14,
    },
  },
  testPoints: {
    fontSize: 16,
    lineHeight: "155%",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    background: "#E7E7E7",
    borderRadius: "4px",
    padding: "0 5px",
    display: "inline-block",
    marginTop: 0,
    marginBottom: 12,
    marginLeft: 20,
  },
  testNotice: {
    "& p": {
      fontSize: 16,
      lineHeight: "24px",
      letterSpacing: "0.3px",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 300,
      marginTop: 12,
      marginBottom: 12,
      marginLeft: 18,
      position: "relative",
      display: "block",
    },
    "& p::before": {
      width: 5,
      height: "auto",
      left: -18,
      top: 0,
      bottom: 0,
      background: "#313439",
      borderRadius: "2px",
      position: "absolute",
      display: "block",
      content: "''",
    },
  },
  testImg: {
    marginTop: 0,
    marginBottom: 7,
    marginRight: 0,
    marginLeft: 0,
    maxWidth: 800,
    maxHeight: 480,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100% !important",
      width: "100% !important",
    },
  },
  testButtonSubmit: {
    cursor: "pointer",
    appearance: "none",
    borderRadius: "4px",
    background: "#1665c1",
    color: "#ffffff",
    width: "200px",
    height: "44px",
    fontSize: "16px",
    border: "1px solid #1665c1",
    textTransform: "uppercase",
    fontWeight: 400,
    fontFamily: "Roboto",
    "&:hover": {
      background: "#115eb6",
      borderColor: "#115eb6",
      color: "#ffffff",
    },
    "&:disabled": {
      background: "#ebebeb",
      borderColor: "#98999d",
      color: "#98999d",
    },
  },
  testHeader: {
    padding: 36,
    marginBottom: 48,
    marginTop: 58,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: 1280,
    borderRadius: "6px",
    borderWidth: "1px",
    borderStyle: "solid",
    "& h4": {
      fontSize: 18,
      lineHeight: "155%",
      fontWeight: 600,
      fontFamily: "Roboto",
    },
    "& p": {
      fontSize: 18,
      lineHeight: "155%",
      fontWeight: 400,
      fontFamily: "Roboto",
    },
  },
  testCheckbox: {
    paddingBottom: 6,
    paddingTop: 6,
  },
  testDetailed: {
    borderRadius: "4px",
    marginBottom: 25,
    marginTop: 12,
    "& .MuiOutlinedInput-root fieldset": {
      borderColor: "#C3C3C3",
    },
    "& textarea": {
      fontSize: 18,
      lineHeight: 1,
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 300,
    },
    "& > div": {
      paddingBottom: 10,
      paddingTop: 12,
      paddingLeft: 10,
      paddingRight: 10,
    },
    "& > label": {
      fontSize: 18,
      lineHeight: 1,
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 300,
      top: "-5px",
      color: "#98999C",
    },
  },
  /* end new test styles */

  contentText: {
    "& > *": {
      marginBottom: 12,
      fontFamily: "Roboto",
      color: "rgba(49, 52, 56, 1)",
      fontSize: 18,
      fontWeight: 100,
    },
    "& p": {
      fontSize: 18,
      lineHeight: "150%",
      marginTop: 18,
      marginBottom: 18,
    },
    "& iframe": {
      width: 800,
      border: 0,
      display: "block",
      margin: "24px auto 48px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
        height: "auto",
      },
    },
    "& a": {
      margin: 0,
      target: "_blank",
      rel: "noopener noreferrer",
      color: "#376fd0",
      textDecoration: "none",
      cursor: "pointer",
    },
    "& a:hover": {
      color: "#376fd0",
      borderBottom: "1px solid #376fd0",
    },
    "& b": {
      fontWeight: 500,
    },
    "& h2": {
      marginTop: 48,
      fontSize: 20,
      fontWeight: 400,
      textTransform: "uppercase",
    },
    "& h4": {
      marginTop: 48,
      fontSize: 16,
      fontWeight: 400,
      textTransform: "uppercase",
    },
    "& img": {
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100% !important",
      },
      display: "block",
      marginTop: 40,
      marginBottom: 18,
      border: "1px solid rgba(224, 224, 224, 1)",
      borderRadius: 6,
    },
    "& ul": {
      marginLeft: theme.spacing(6),
      marginTop: 6,
      marginBottom: theme.spacing(3),
      listStyleType: "disc",
      [theme.breakpoints.down("sm")]: {
        marginLeft: theme.spacing(2),
      },
    },
    "& ol": {
      marginLeft: 24,
      marginTop: 6,
      marginBottom: 18,
      listStyleType: "decimal",
    },
    "& li": {
      lineHeight: 1.5,
      marginLeft: 10,
      marginTop: 0,
      marginBottom: 0,
      marginRight: 0,
      [theme.breakpoints.down("sm")]: {
        margin: 0,
      },
    },
    "& i": {
      fontWeight: 400,
      fontStyle: "italic",
      color: "#797979",
    },
    "& .contentwrapper-cnti0 p": {
      textAlign: "justify",
    },
    //images
    "& .wrapimg, .wrapimg-cnti0": {
      marginTop: 24,
      marginBottom: 48,
      marginRight: 0,
      marginLeft: 0,
    },
    "& .wrapimg img, .wrapimg-cnti0 img": {
      marginTop: 0,
      marginBottom: 6,
      marginRight: "auto",
      marginLeft: "auto",
      display: "block",
    },
    "& .wrapimg span, .wrapimg-cnti0 span": {
      fontSize: 14,
      textAlign: "center",
      display: "block",
    },
    //taskpage
    "& .tasklist, .tasklist-cnti0": {
      margin: 0,
    },
    "& .tasklist p, .tasklist-cnti0 p": {
      marginTop: 28,
    },
    "& .forchecking, .forchecking-cnti0": {
      marginTop: 48,
      fontWeight: 400,
      paddingTop: 12,
      borderTop: "1px solid #ddd",
    },
    // red notice
    "& .notice-cnti0": {
      fontWeight: 400,
      color: "#d13636",
      fontSize: 14,
    },
    // tables
    "& .table-cnti0": {
      width: "100%",
      maxWidth: 1000,
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "48px !important",
      borderCollapse: "collapse",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
    "& .table-cnti0 td": {
      border: "1px solid #c3c3c3",
      padding: 10,
    },
    "& .tblcenter-cnti0 td": {
      textAlign: "center",
    },
    "& .table-cnti0 th": {
      backgroundColor: "#eff2f9",
      border: "1px solid #c3c3c3",
      fontWeight: 400,
      textAlign: "center",
      padding: "5px 0",
    },
    //code blocks
    "& .code-cnti0": {
      marginBottom: 48,
      padding: 18,
      backgroundColor: "#eff2f9",
      borderRadius: 6,
    },
    "& .keyword-cnti0": {
      color: "#069",
    },
    "& .comments-cnti0": {
      color: "#008200",
    },
    //emko blocks
    "& .basics-cnti0": {
      border: "1px solid rgb(21, 101, 192)",
      boxShadow: "rgb(54 54 54 / 10%) 0px 0px 10px",
      padding: "30px 30px 20px",
      margin: 48,
      borderRadius: 6,
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        margin: "0px",
      },
    },
    "& .basics-h-cnti0": {
      position: "absolute",
      top: "-14px",
      background: "#fff",
      color: "rgb(21, 101, 192)",
      padding: "0px 10px",
      left: 10,
      textTransform: "uppercase",
      margin: 0,
    },
    "& .basics-h-cnti0 b": {
      fontSize: 20,
      fontWeight: 500,
    },
    "& .basics-cnti0 p": {
      margin: "2px 0 0",
    },
    // tooltip
    "& .tooltip-cnti0": {
      position: "relative",
      display: "inline-block",
      borderBottom: "1px dotted #797979",
      cursor: "pointer",
      fontWeight: 400,
      fontStyle: "italic",
      color: "#797979",
    },
    "& .tooltip-cnti0 .tooltiptext-cnti0": {
      visibility: "hidden",
      width: 200,
      backgroundColor: "#555",
      color: "#fff",
      textAlign: "left",
      borderRadius: 6,
      padding: "7px 10px",
      position: "absolute",
      zIndex: 1,
      bottom: "125%",
      left: "50%",
      marginLeft: "-100px",
      opacity: 0,
      transition: "opacity 0.3s",
      fontSize: 14,
      lineHeight: 1.2,
      fontStyle: "normal",
    },
    "& .tooltip-cnti0 .tooltiptext-cnti0::after": {
      content: "",
      position: "absolute",
      top: "100%",
      left: "50%",
      marginLeft: "-5px",
      borderWidth: 5,
      borderStyle: "solid",
      borderColor: "#555 transparent transparent transparent",
    },
    "& .tooltip-cnti0:hover .tooltiptext-cnti0": {
      visibility: "visible",
      opacity: 1,
    },
    "& .tooltiptext-cnti0 i": {
      color: "#fff",
    },
  },
}))

export const AdaptiveTestContainer = styled("div")(({ theme }) => ({
  paddingRight: "5vw",
  paddingLeft: "5vw",
  width: "100%",
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "column",
  justifyContent: "flex-start",
  gap: theme.spacing(10),
  userSelect: "none",
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))

export const StyledReadySetter = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: theme.spacing(5),
}))

export const StyledSendAnswersContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: theme.spacing(4),
}))

export const ModalNextTestWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(12),
  paddingLeft: theme.spacing(6),
}))

export const ContentModalWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: theme.spacing(6),
}))
