import React from "react"
import ReactDOM from "react-dom/client"
import App from "app"
import * as serviceWorker from "./app/serviceWorker"
import { UserProvider } from "shared/context"
import * as Sentry from "@sentry/react"

Sentry.init({
    dsn: "https://7029d33b5a0c5e30d9acabcdbbf45a88@sentry.syncwoia.com/2",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        })],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["https://test.syncwoia.com", /^https:\/\/test.syncwoia.com\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <UserProvider>
    <App />
  </UserProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
