import React, { useState, useContext } from "react"
import axios from "axios"

let UserStateContext = React.createContext()

function UserProvider({ children }) {
  const [state, setState] = useState(null);
  const [openLogin, setOpenLogin] = useState(false);
  const [openRegistration, setOpenRegistration] = useState(false);

  const isAuthenticated = Boolean(localStorage.getItem("token"));

  React.useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "logout") {
        window.location.reload();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <UserStateContext.Provider
      value={{
        user: { state, setState },
        login: { open: openLogin, setOpen: setOpenLogin },
        registration: { open: openRegistration, setOpen: setOpenRegistration },
        isAuthenticated,
      }}
    >
      {children}
    </UserStateContext.Provider>
  );
}


function useUserState() {
  let context = useContext(UserStateContext)
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider")
  }
  return context
}

export { useUserState, UserProvider, receiveToken, signOut }

function signOut() {
  localStorage.clear();
  document.cookie = "token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  axios.defaults.headers.common["Authorization"] = "";

  localStorage.setItem("logout", Date.now());

  window.location.reload();
}


function receiveToken(token, social = false) {
  localStorage.clear()
  if (social) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token.token
    localStorage.setItem("token", token.token)
  } else {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token.access
    localStorage.setItem("token", token.access)
  }

  axios
    .get("/profiles/my_profile")
    .then(res => {
      localStorage.setItem("user", JSON.stringify(res.data.id))
      localStorage.setItem("user_status", res.data.status)
      localStorage.setItem("nickname", res.data.nickname)
      localStorage.setItem("email", res.data.email)
      localStorage.setItem("phone", res.data.phone_num)
    })
    .catch(err => console.error(err))

  localStorage.setItem("token_ref", token.refresh)
  localStorage.setItem("user", JSON.stringify("Waiting"))
  localStorage.setItem("theme", "default")
}
