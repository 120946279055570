import React from "react"
import * as Styled from "./TeamOfOrganization.styles"
import { mentorItems } from "../../../pages/landing/mock"
import { Grid, Typography } from "@mui/material"
import { useTheme } from "../../../shared/context"
import { styled } from "@mui/material/styles"

export const MentorItem = ({
  mentorItem,
}: {
  mentorItem: {
    avatar?: string
    full_name?: string
    about?: string
  }
}) => {
  const theme = useTheme()
  return (
    <MentorItemMainContainer>
      <img src={mentorItem.avatar} style={{ width: "100%" }} alt={"Image of mentor"} />
      <Styled.StyledSubtitle
        variant={"h3"}
        fontSize={"medium"}
        sx={{
          fontWeight: 500,
          color: "#313439",
          marginBottom: theme.spacing(3),
          marginTop: theme.spacing(3),
        }}
      >
        {mentorItem.full_name}
      </Styled.StyledSubtitle>
      <Typography
        variant={"body1"}
        fontWeight={"medium"}
        fontSize={"medium"}
        sx={{ color: "#313439", fontSize: theme.spacing(4) }}
      >
        {mentorItem.about}
      </Typography>
    </MentorItemMainContainer>
  )
}

export const MentorItemMainContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "600px",
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.spacing(3),
  padding: theme.spacing(6),
  [theme.breakpoints.down("lg")]: {
    height: "620px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "600px",
  },
}))
