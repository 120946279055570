import React, { useEffect, useMemo, useState } from "react"
import useStyles, { ModuleMainContainer } from "./styles"
import { Button } from "@mui/material"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import { useContentState, useTheme } from "shared/context"
import { Header, Sidebar } from "widgets"
import {
  CodeEditorBlock,
  FileUploadBlock,
  MultipartContentBlock,
  ReviewBlock,
  TableBlock,
  TestBlock,
  WokwiBlock,
} from "widgets/contentBlocks"
import { Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { TimerExitModalUnstored } from "widgets/TimerStartModal/TimerExitModalUnstored"
import { AdaptiveTestBlock } from "../../widgets/contentBlocks/AdaptiveTestBlock"

export default function Module(props) {
  const classes = useStyles()
  const module_id = props.match.params.id
  const theme = useTheme()
  const team = props.match.params.team
  const { current, setCurrent } = useContentState()
  const [content, setContent] = useState({})
  const [progress, setProgress] = useState(0)
  const [status, setStatus] = useState(null)
  const [showSidebar, setShowSidebar] = useState(true)
  const [isComponents, setIsComponents] = useState(false)
  const [contentBlocks, setContentBlocks] = useState(false)
  const [courseSlug, setCourseSlug] = useState("")
  const [isExitModal, setExitModal] = useState(false)
  const [nextTabCount, setNextTabCount] = useState(null)
  const [nextTabState, setNextTabState] = useState(null)
  const isRunningTimerTest = useSelector(state => state.timer.isRunning)

  const today = useMemo(() => {
    return new Date()
  }, [props])

  useEffect(() => {
    let old = JSON.parse(localStorage.getItem(`navBarState${module_id}`))
    if (!!old && !!old.theme) localStorage.removeItem(`navBarState${module_id}`)
    setCurrent(JSON.parse(localStorage.getItem(`navBarState${module_id}`)) || { section: 1, subsection: 1 })
  }, [])

  function isAccessible(status, is_active, start, finish) {
    if (
      new Date(finish).setHours(23, 59, 59) <= today &&
      new Date(finish) > new Date(start) &&
      (status === "mentor" || status === "in_progress")
    ) {
      return false
    } else if (!status) {
      return is_active
    } else if (!start) {
      return is_active || status === "teacher" || status === "moderator"
    }
    return (is_active && new Date(start) <= today) || status === "teacher" || status === "moderator"
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`/content/modules/${module_id}/`)
        try {
          const r = await axios.get("/content/actions/courses/my_status/", {
            params: { course_id: res.data.course_id },
          })
          setStatus(r.data.status)
          let access = isAccessible(r.data.status, res.data.is_active, res.data.start, res.data.finish)
          if (!access) {
            window.location.href = "/"
            return
          }
        } catch (err) {
          window.location.href = "/"
          return
        }
        var sortedContent = res.data
        setCourseSlug(sortedContent.course_slug)
        sortedContent.sections = sortedContent.sections.sort((a, b) => (a.index > b.index ? 1 : -1))
        for (let sectionIndex = 0; sectionIndex < sortedContent.sections.length; sectionIndex++) {
          sortedContent.sections[sectionIndex].subsections = sortedContent.sections[sectionIndex].subsections.sort(
            (a, b) => (a.index > b.index ? 1 : -1)
          )
        }
        setContent(sortedContent)
        let thisBlock = JSON.parse(localStorage.getItem(`navBarState${module_id}`)) || {
          section: 1,
          subsection: 1,
        }
        let id = sortedContent.sections[thisBlock.section - 1].subsections[thisBlock.subsection - 1].id
        const subsectionResponse = await axios.get(`/content/subsections/${id}/`)
        let data = subsectionResponse.data
        data.blocks = data.blocks.sort((a, b) => (a.index > b.index ? 1 : -1))
        setContentBlocks(data)
      } catch (err) {
        window.location.href = "/"
      }
    }
    fetchData()
  }, [module_id])

  useEffect(() => {
    if (content.sections) setIsComponents(true)
  }, [content])

  useEffect(() => {
    axios.get(`/content/module_progress/my_module_progress/?module__id=${module_id}&check_timer=${true}`).then(res => {
      setProgress(res.data[0])
    })
  }, [])

  useEffect(() => {
    if (Object.keys(content).length) {
      saveCurrent(current)
    }
  }, [current])

  function contentMapper(block) {
    if (!isActive(current)) return
    switch (block.type) {
      case "adaptive-test":
        return (
          <div key={block.id} style={{ width: "100%" }}>
            <AdaptiveTestBlock
              showButtonRestart={status === "moderator"}
              props={block}
              changeSubsection={changeSubsection}
            />
          </div>
        )
      case "test":
        return <TestBlock block={block} courseSlug={courseSlug} manual={false} team={team} />
      case "manual-test":
        return <TestBlock block={block} courseSlug={courseSlug} manual={true} team={team} />
      case "multipart-content":
        return <MultipartContentBlock block={block} />
      case "table":
        return <TableBlock block={block} />
      case "code-editor":
        return <CodeEditorBlock block={block} />
      case "upload":
        return <FileUploadBlock block={block} />
      case "review":
        return <ReviewBlock block={block} course={content.course_id} />
      case "wokwi":
        return <WokwiBlock block={block} />
      default:
        return
    }
  }

  function isActive(state) {
    return (
      content.sections[state.section - 1] &&
      content.sections[state.section - 1].subsections[state.subsection - 1].is_active
    )
  }

  function saveCurrent(state) {
    setExitModal(false)
    setNextTabCount(null)
    setNextTabState(null)
    if (isActive(state)) {
      let id = content.sections[state.section - 1].subsections[state.subsection - 1].id
      axios.get(`/content/subsections/${id}/`).then(res => {
        let data = res.data
        data.blocks = data.blocks.sort((a, b) => (a.index > b.index ? 1 : -1))
        setContentBlocks(data)
      })
      localStorage.setItem(`navBarState${module_id}`, JSON.stringify(state))
      setCurrent(state)
      window.scrollTo(0, 0)
    }
  }

  async function changeSubsection(counter) {
    setNextTabCount(counter)
    isRunningTimerTest
      ? setExitModal(true)
      : saveCurrent({ section: current.section, subsection: current.subsection + counter })
  }

  const handleSaveCurrent = state => {
    setNextTabState(state)
    isRunningTimerTest ? setExitModal(true) : saveCurrent(state)
  }

  const handleChangeSubsection = () => {
    saveCurrent({ section: current.section, subsection: current.subsection + nextTabCount })
  }

  function getModule() {
    if (
      current.section > content.sections.length ||
      current.subsection > content.sections[current.section - 1].subsections.length
    ) {
      saveCurrent({ section: 1, subsection: 1 })
      return false
    }
    return content.sections[current.section - 1].subsections[current.subsection - 1]
  }

  useEffect(() => {
    console.log(contentBlocks?.blocks?.filter(e => e.type === "adaptive-test"))
  }, [contentBlocks])

  if (!content.sections || !getModule()) return <></>

  return (
    <>
      {/* TODO этой модалке тут не место, перепиши её с помощью промисов и приуроч к TimerExitModal в TestBlock */}
      {isExitModal && (
        <TimerExitModalUnstored
          isOpen={isExitModal}
          onExit={() => (nextTabState ? saveCurrent(nextTabState) : handleChangeSubsection())}
          onClose={() => setExitModal(false)}
          onContinue={() => setExitModal(false)}
        />
      )}
      <Header variant={"shadow"} />
      <ModuleMainContainer
        sx={!showSidebar ? { paddingLeft: 0, [theme.breakpoints.down("sm")]: { paddingLeft: "5vw" } } : {}}
      >
        <div style={{ minHeight: "calc(100vh - 185px)" }}>
          <div style={{ display: "flex", alignItems: "flex-start", flexDirection: "column", minHeight: "80vh" }}>
            <Typography
              variant="h3"
              style={{
                marginLeft: "5vw",
                marginRight: "5vw",
                fontWeight: "bold",
                marginBottom: 24,
                width: "65vw",
              }}
            >
              {!!contentBlocks?.blocks
                ? contentBlocks?.blocks?.filter(e => e.type === "adaptive-test").length > 0
                  ? `${getModule().title}`
                  : `${current.section}.${current.subsection} ${getModule().title}`
                : `${getModule().title}`}
            </Typography>
            {!!contentBlocks && contentBlocks.blocks.map(contentMapper)}
          </div>
          <Sidebar
            hasAdaptiveTest={
              !!contentBlocks?.blocks ? contentBlocks?.blocks?.filter(e => e.type === "adaptive-test").length > 0 : true
            }
            showSidebar={showSidebar}
            setShowSidebar={setShowSidebar}
            content={content}
            setCurrent={handleSaveCurrent}
            progress={progress || { score: 0 }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              paddingBottom: 96,
            }}
          >
            {contentBlocks?.blocks?.filter(e => e.type === "adaptive-test").length === 0 && (
              <>
                <Button
                  style={{ margin: 24 }}
                  variant="contained"
                  color="primary"
                  disabled={!(current.subsection - 1)}
                  onClick={() => changeSubsection(-1)}
                >
                  Назад
                </Button>
                <Button
                  style={{ margin: 24 }}
                  variant="contained"
                  color="primary"
                  disabled={!content.sections[current.section - 1].subsections[current.subsection]}
                  onClick={() => changeSubsection(1)}
                >
                  Вперед
                </Button>
              </>
            )}
          </div>
        </div>
      </ModuleMainContainer>
    </>
  )
}
